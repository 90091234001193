/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import calenderIcon from "../../asset/images/calender.svg"

interface IDataDatePicker {
  control?: any;
  name: string;
  rules?: any;
  error?: any;
  label?: string;
  showIcon?: boolean;
  isClearable?: boolean;
  required?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  monthsShown?: number;
  maxDate?: any;
  minDate?: any;
  endDate?: Date;
  startDate?: Date;
  value?: any;
  onChange?: Function;
  isMultiSelect?: boolean;
}

const Calender: React.FC<IDataDatePicker> = (props) => {
  const {
    control,
    name,
    rules,
    error,
    label,
    required,
    selectsStart,
    selectsEnd,
    className,
    inputClassName,
    placeholder,
    monthsShown = 1,
    maxDate = null,
    minDate = null,
    endDate = null,
    startDate = null,
    showIcon = true,
    isClearable = false,
    isMultiSelect=false
  } = props

  const renderInput = (field:any) => {
    return (
      <DatePicker
        placeholderText={`${placeholder ? placeholder : "Select date"}`}
        onChange={(date: Date) => {
            // Ensure the onChange event is triggered only after both date and time are selected
            if (date) {
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const seconds = date.getSeconds();
    
              // Check if a time has been selected
              if (hours !== 0 || minutes !== 0) {
                field?.onChange(date);
                props?.onChange && props.onChange(date);
              }
              if (hours === 0 && minutes === 0 && seconds === 0) {
                field?.onChange(date);
                props?.onChange && props.onChange(date);
              }
            }}}
        showTwoColumnMonthYearPicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={field?.value ? new Date(field?.value) : null}
        timeFormat="HH:mm"
        dateFormat="yyyy-MM-dd HH:mm:ss"
        className={`outline-none h-[37px] text-sm w-[170px] border rounded-md gap-2  ${!error
            ? "border-[#CCD0CF] focus:outline-none focus:ring-blue-500 focus:border-blue-500 "
            : "border-red-600"
          } ${inputClassName}`}
        wrapperClassName="w-full "
        maxDate={maxDate}
        minDate={minDate}
        showTimeSelect
        popperClassName="custom-dropdown"
        id={`input_time-${name}`}
        icon={
          <label
            htmlFor={`input_time-${name}`}
            className="cursor-pointer py-2"
          >
            <img src={calenderIcon} alt="" className="text-white mt-1" />
          </label>
        }
        showIcon={showIcon}
        startDate={startDate}
        endDate={endDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        isClearable={isClearable}
        monthsShown={monthsShown}
        selectsMultiple={isMultiSelect}
      />
    )
  }
  return (
    <div className={`calender-selection flex flex-col ${className}`}>
      {label && (
        <label htmlFor={name} className="text-[#4E4E4E] text-sm mb-[1px]">
          {label}
          {required && <span className="text-red-500"> *</span>}
        </label>
      )}
      {control ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <div className="w-full">
              {renderInput(field)}
              
            </div>
          )}
        />
      ) : (
        <div className="w-full">
          {renderInput(props)}
          
        </div>
      )}
    </div>
  );
};

export default Calender;
