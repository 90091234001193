/* eslint-disable */
import { useDispatch } from 'react-redux';
import Form from './Form'
import { useWebSocket } from '../../../../api/websocket/WebSocketContext';
import { useEffect, useState } from 'react';
import { get_order_date_setting, setting_order_date } from '../../../../api/websocket/payload';
import { setFooterShippingDates } from '../../../../../redux/actions/action';
import { toast } from 'react-toastify';
import FormforOrderPerDay from './FormforOrderPerDay';


const FooterOrders = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    if (isConnect) {
      const message = get_order_date_setting;
      send(message);
    }
  }, [isConnect, receivedMessage?.url]);
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === setting_order_date.url) {
          if(receivedMessage?.response?.status===200)
            toast.success(receivedMessage?.response?.msg);
          
        }
        if (receivedMessage?.url === get_order_date_setting.url) {
          dispatch(setFooterShippingDates(receivedMessage?.response?.data));
        // console.log("receivedMessage?.response?.data",receivedMessage?.response?.data)

        }
      }
    }

   
  }, [receivedMessage]);


  return (
    <div className='flex flex-col w-full'>
      <Form setLoading={setLoading} loading={loading}/>
      <FormforOrderPerDay setLoading={setLoading} loading={loading}/>
      </div>
  )
}

export default FooterOrders