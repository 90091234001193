/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { usePosterReducers } from "../../../redux/getdata/usePostReducer";
import { clearRedux, setLoader, setSocketStatus } from "../../../redux/actions/action";
import { toast } from "react-toastify";
import { useUiReducer } from "../../../redux/getdata/useUiReducer";

interface WebSocketContextType {
  websocket: WebSocket | null;
  send: (data: any) => void;
  receivedMessage: any | null;
  isConnect: boolean;
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [websocket, setWebSocket] = useState<WebSocket | null>(null);
  const [receivedMessage, setReceivedMessage] = useState<any | null>(null);
  const [isConnect, setIsConnect] = useState<boolean>(false);
  const { accessToken } = usePosterReducers();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate=useNavigate();

  const connectWebSocket = () => {
    const socket = new WebSocket(`${process.env.REACT_APP_WENSOCKET_URL}/${accessToken}/` || "");
    
    socket.onopen = (event) => {
      event.preventDefault();
      event.stopPropagation();
      
      setIsConnect(true);
      setWebSocket(socket);
      dispatch(setSocketStatus(true))
    };

    socket.onclose = () => {
     
      setIsConnect(false);
      dispatch(setSocketStatus(false))
      setWebSocket(null);

    };

    socket.onerror = (error) => {
     
      dispatch(setSocketStatus(false))
      setIsConnect(false);
      setWebSocket(null);
    };

    socket.onmessage = (event) => {
      const data=JSON.parse(event.data)
      // console.log("socket.onmessage ::",data);
      
      dispatch(setLoader(false));
      
      if(data?.url==='unauthorized'){
        dispatch(clearRedux())
        navigate('/login')
      }

      if(data?.response?.status===200){
        setReceivedMessage(JSON.parse(event?.data));
        
        }
    
      if(data?.response?.status===400){
        setReceivedMessage(JSON.parse(event?.data));
        // toast.warning(data?.response?.msg);
      }
    };

    return socket;
  };

  useEffect(() => {
    let socket: WebSocket | null = null;
    if (location.pathname !== "/login" && location.pathname !== "/") {
      socket = connectWebSocket();

      const reconnectInterval = setInterval(() => {
        if (!socket || socket.readyState === WebSocket.CLOSED) {
          socket = connectWebSocket();
        }
      }, 3000);

      return () => {
        clearInterval(reconnectInterval);
        if (socket) {
          socket.close();
        }
      };
    }
  },[location.pathname !== "/login" && location.pathname !== "/"]);

  const send = (data: any) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {

      
      if (isConnect) {
        dispatch(setLoader(true));
        websocket.send(JSON.stringify(data));
      }
    } else {
      dispatch(setSocketStatus(false))
      
    }
  };

  const contextValue: WebSocketContextType = {
    websocket: websocket,
    send: send,
    receivedMessage: receivedMessage,
    isConnect: isConnect,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
