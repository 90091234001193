import React, { useEffect, useRef } from "react";
import { IToggleModalTypes } from "../../../utils/types";

interface IFormPopupProps {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  children: React.ReactNode;
  handleSubmit?: any;
  onSubmit?: Function;
  containt: {
    title: string;
    submit: string;
    close: string;
  };
}

const FormPopup: React.FC<IFormPopupProps> = (props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the dropdown container
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        props.toggleModal("hide");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  
  return (
    <>
      {props.showModal !== "hide" && (
        <div
          id="default-modal"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-400 bg-opacity-50"
        >
          <div className="relative p-4    w-full max-w-lg ">
            <div
              ref={dropdownRef}
              className="relative bg-white rounded-lg   shadow"
            >
              <form
                onSubmit={props.handleSubmit(props.onSubmit)}
              >
                <div className="flex items-center justify-between border-b p-4 md:p-4 rounded-t">
                  <div className="w-full text-center">
                    <h3 className="text-xl  dark:text-black">
                      {props.containt.title}
                    </h3>
                  </div>
                  <button
                    onClick={() => props.toggleModal("hide")}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-[#2737FF] rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-[#2737FF] dark:hover:text-white"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">{props.containt.close}</span>
                  </button>
                </div>

                <div
                  id="modal-sidebar"
                  className="p-2 px-4 overflow-auto max-h-96 "
                >
                  {props.children}
                </div>

                <div className="flex items-center border-t justify-center p-3 md:p-3 mt-2 ">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {props.containt.submit}
                  </button>
                  <button
                    onClick={() => props.toggleModal("hide")}
                    className="py-2.5 px-5 ms-3 text-sm font-medium  bg-gray-300 rounded-lg"
                  >
                    {props.containt.close}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormPopup;
