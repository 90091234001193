/* eslint-disable */
import { useEffect } from "react";
import FormLayout from "../../../components/FormLayout";
import { useForm } from "react-hook-form";
import { IFooterSetting } from "../../../../utils/types";
import InputField from "../../../components/input/InputField";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { setting_update } from "../../../../api/websocket/payload";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";

const Form = () => {
  const { register, handleSubmit,reset,formState:{errors} } = useForm<IFooterSetting>();
const {send,receivedMessage}=useWebSocket()
  const {footer_setting}=usePosterReducers()
  // useEffect(() => {
  //   // reset({...footer_setting})
  //   reset({
  //     ...footer_setting,
  //     data: {
  //       ...footer_setting?.data,
  //       local_shipping_charge: (Number(footer_setting?.data?.local_shipping_charge) / 100).toString(),
  //       non_local_shipping_charge: (Number(footer_setting?.data?.non_local_shipping_charge) / 100).toString(),
  //       additional_quantity_charge: (Number(footer_setting?.data?.additional_quantity_charge) / 100).toString(),
  //     }
  //   });
  // },[receivedMessage]);
  useEffect(() => {
    const safeNumber = (value:any) => {
      const num = Number(value);
      return isNaN(num) ? "" : (num / 100).toString(); // Return empty string if NaN, otherwise divide by 100
    };
  
    reset({
      ...footer_setting,
      data: {
        ...footer_setting?.data,
        local_shipping_charge: safeNumber(footer_setting?.data?.local_shipping_charge),
        non_local_shipping_charge: safeNumber(footer_setting?.data?.non_local_shipping_charge),
        additional_quantity_charge: safeNumber(footer_setting?.data?.additional_quantity_charge),
      }
    });
  }, [receivedMessage]);
    

  const onSubmit=(data:IFooterSetting)=>{
    // const payload={...data,data:{...data.data}}
    
    const { shipping_charge, ...remainingData } = data?.data;
    const payload = {
             ...data,
             data: {
                 ...remainingData, 
                 local_shipping_charge: Number(remainingData?.local_shipping_charge) * 100,
                 non_local_shipping_charge: Number(remainingData?.non_local_shipping_charge) * 100,
                 additional_quantity_charge: Number(remainingData?.additional_quantity_charge) * 100,
             }
      };
     
    // delete data.data?.shipping_charge
    setting_update.request.data=payload.data
    send(setting_update);
   
  }
  return (
    <FormLayout
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      containt={{
        title: "Setting",
        submit: "Save",
      }}
    >
      <div className="lg:flex w-full gap-4">
        <InputField
        register={register('data.contact.email')}
          name={"Email"}
          placeholder={"Enter Email"}
          inputClassName="h-9"
          className="my-2 w-full"
        />
        <InputField
        register={register('data.contact.phone',{
          pattern: {
            value: /^[\d-]+$/,
            message: "Contact number must be a number eg. 616-965-1565",
          },
        })}
          name={"Contact Number"}
          placeholder={"Enter Contact Number"}
          inputClassName="h-9"
          className="my-2 w-full"
          error={errors.data?.contact?.phone}
        />
        
      </div>
      <div className="lg:flex w-full gap-4">
      <InputField
          register={register('data.local_shipping_charge',{
            required: "Local shipping charge  is required",
            pattern:{
              value:/^(?!0(\.0+)?$)([1-9]\d*|0)(\.\d{1,2})?$/,
              message: "Please enter a valid number with up to 2 decimal places",
            }             
          })}
          required
          name={"Local Shipping Charge"}
          placeholder={"Local Shipping Charge"}
          inputClassName="h-9"
          className="my-2 w-full "
          error={errors?.data?.local_shipping_charge}        
        />
        <InputField
          register={register(`${`data.non_local_shipping_charge`}`, {
            required: "Non local shipping charge  is required",
            pattern:{
              value:/^(?!0(\.0+)?$)([1-9]\d*|0)(\.\d{1,2})?$/,
              message: "Please enter a valid number with up to 2 decimal places",
            }  
          })}
          required
          name={"Non local Shipping Charge"}
          placeholder={"Non Local Shipping Charge"}
          inputClassName="h-9"
          className="my-2 w-full "
          error={errors?.data?.non_local_shipping_charge}
        />
        <InputField
          register={register('data.additional_quantity_charge',{
            required: "Additional quantity charge  is required",
            pattern:{
              value:/^(?!0(\.0+)?$)([1-9]\d*|0)(\.\d{1,2})?$/,
              message: "Please enter a valid number with up to 2 decimal places",
            }  
          })}
          required
          name={"Additional Quantity Charge"}
          placeholder={"Additional Quantity Charge"}
          inputClassName="h-9"
          className="my-2 w-full "
          error={errors?.data?.additional_quantity_charge}
        />
      </div>
      <div className="lg:flex w-full gap-4">
        <InputField
        register={register('data.social.facebook')}
          name={"Facebook URL"}
          placeholder={"Enter Facebook URL"}
          inputClassName="h-9"
          className="my-2 w-full "
        />
        <InputField
        register={register('data.social.instagram')}
          name={"Instagram URL"}
          placeholder={"Enter Instagram URL"}
          inputClassName="h-9"
          className="my-2 w-full "
        />
        <InputField
        register={register('data.social.twitter')}
          name={"X URL"}
          placeholder={"Enter Twitter URL"}
          inputClassName="h-9"
          className="my-2 w-full "
        />
        <InputField
        register={register('data.social.linkedin')}
          name={"Linkedin URL"}
          placeholder={"Enter Linkedin URL"}
          inputClassName="h-9"
          className="my-2 w-full "
        />
      </div>
      <div className="lg:flex gap-4 w-full">
       
        <InputField
        register={register('data.address')}
          name={"Address For Website"}
          placeholder={"Enter Address For Website"}
          inputClassName="h-16"
          className="my-2 w-full"
          useFor="textarea"
        />
      </div>
      
    </FormLayout>
  );
};

export default Form;
