/* eslint-disable */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FormLayout from "../../../components/FormLayout";
import { useForm, Controller } from "react-hook-form";
import { IFooterOrderSetting,IFooterOrderSetting2 } from "../../../../utils/types";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import DatePicker from "react-datepicker";
interface IAppProps {
  setLoading: Dispatch<SetStateAction<boolean>>; 
  loading:boolean
}
const Form : React.FunctionComponent<IAppProps> = ({setLoading,loading}) => {
  const { register, handleSubmit, reset, control, watch, formState: { errors } } = useForm<IFooterOrderSetting>();
  const { send, receivedMessage } = useWebSocket();
  const { shipping_dates } = usePosterReducers();
  
  const [startDate, setStartDate] = useState<Date | null>(null);
  const toDate = watch('data.excluded_days.to');
  const fromDate = watch('data.excluded_days.from');

  
  // Populate initial values on receiving data
  useEffect(() => {
    const fromDate = shipping_dates?.data?.excluded_days?.from;
    const toDate = shipping_dates?.data?.excluded_days?.to;

    const validFromDate = fromDate ? new Date(fromDate) : null;
    const validToDate = toDate ? new Date(toDate) : null;
    if(!loading){
      reset({
        data: {
          ...shipping_dates,
          excluded_days: {
            ...shipping_dates?.data,
            from: validFromDate,
            to: validToDate,
          },
        }
      });
    }
  }, [receivedMessage]);

  const onSubmitExcludedDates = (data: IFooterOrderSetting) => {
 const payload = {
      transmit: "single",
      url: "setting_order_date_update",
      request: {
       data: {
        excluded_days: {
          from: data?.data?.excluded_days?.from
            ?.toString()
            .replace(/^[A-Za-z]+\s/, "")
            .split(" GMT")[0],
          to: data?.data?.excluded_days?.to
            ?.toString()
            .replace(/^[A-Za-z]+\s/, "")
            .split(" GMT")[0],
        },
        orders_per_day: shipping_dates?.data?.orders_per_day
      },
        }
      }
      send(payload);
      setLoading(true)
      console.log("payload in date form",payload)

    }  
 
  const filterAllowedDays = (date: Date) => {
    const day = date.getDay();
    return day >= 2 && day <= 5;
  };

  const calculateMinDeliveryDate = (): Date | null => {
    const selectedDate = new Date();
    const minDeliveryDate = new Date(selectedDate);
    minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
    while (minDeliveryDate.getDay() < 2 || minDeliveryDate.getDay() > 5) {
      minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
    }
    return minDeliveryDate;
  };
  const minDeliveryDate = calculateMinDeliveryDate();

  return (
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmitExcludedDates}
        containt={{ title: "Service Not Available Dates", submit: "Save" }}
      >
        <div className="lg:flex w-[100%] gap-2">
          <div className="my-2 w-full">
            <div className="flex w-full gap-3 mt-[20px]">
              <div className="w-[100%]">
                <label htmlFor="date" className="block font-medium text-gray-600 text-sm mb-[1px]">
                  Exclude Start Date <span className="text-red-500"> *</span>
                </label>
                <Controller
                  name="data.excluded_days.from"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Start date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Start Date"
                      onChange={(date) => {
                        field.onChange(date);
                        setStartDate(date);
                      }}
                      selected={field.value}
                      filterDate={filterAllowedDays}
                      minDate={minDeliveryDate}
                      dateFormat="yyyy-MM-dd"
                      className="lg:w-80 px-3 text-sm py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  )}
                />
                {errors?.data?.excluded_days?.from?.message && (
                  <p className="text-xs mx-1 text-red-500">{errors.data.excluded_days.from.message.toString()}</p>
                )}
              </div>

              <div className="w-[100%]">
                <label htmlFor="date" className="block font-medium text-gray-600 text-sm mb-[1px]">
                  Exclude End Date <span className="text-red-500"> *</span>
                </label>
                <Controller
                  name="data.excluded_days.to"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "End date is required" }}
                  render={({ field }) => (
                      <DatePicker
                      placeholderText="End Date"  
                      onChange={(date) => {
                          field?.onChange(date)
                          setStartDate(date)
                          // console.log("date",date)
                      }}
                      endDate={fromDate}            
                      selected={field?.value}
                      filterDate={filterAllowedDays}
                    //   minDate={minDeliveryDate}
                      minDate={startDate}
                      startDate={toDate}
                      showMonthDropdown
                      showYearDropdown  
                      // selected={startDate}                
                      dateFormat="yyyy-MM-dd"
                      className="lg:w-80 px-3 text-sm py-2 border border-gray-300 rounded-md  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    
                        />
)}
                />
                {errors?.data?.excluded_days?.to?.message && (
                  <p className="text-xs mx-1 text-red-500">{errors.data.excluded_days.to.message.toString()}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </FormLayout>
  );
};

export default Form;

