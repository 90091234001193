import { AiOutlineDashboard } from "react-icons/ai";
import { MdCardGiftcard, MdOutlineShoppingCart } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { RiCoupon2Line } from "react-icons/ri";
import { PiUsersThree } from "react-icons/pi";
import { GoBell } from "react-icons/go";
import { LuCalendarDays } from "react-icons/lu";
// import { TiMessages } from "react-icons/ti";
import { SlSocialDropbox } from "react-icons/sl";

import { IoDocumentTextOutline } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";

import { MdOutlineFeedback } from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";
import { MdOutlineFoodBank } from "react-icons/md";
import { AiOutlineProduct } from "react-icons/ai";
import { BiBookContent } from "react-icons/bi";
import { MdOutlineSettings } from "react-icons/md";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { IoFilterCircleOutline } from "react-icons/io5";
import { BsBoxes } from "react-icons/bs";
import { MdOutlineCategory } from "react-icons/md";
import { CiGift } from "react-icons/ci";
import {
  FOOTER_URL_ORDERS,
  ABOUT_US_URL,
  CATAGORY_MANAGEMENT_URL,
  CATERING_URL,
  COMMUNITY_EVENT_URL,
  COUPON_MANAGEMENT_URL,
  DASHBOARD_URL,
  FEEDBACK_URL,
  FOOTER_URL,
  GIFT_CARDS_URL,
  GIFT_CARD_URL,
  INFO_PAGES_URL,
  KIT_MANAGEMENT_URL,
  ORDER_MANAGEMENT_URL,
  PAYMENT_MANAGEMENT_URL,
  PRODUCT_MANAGEMENT_MAIN_URL,
  PRODUCT_MANAGEMENT_URL,
  PRODUCT_SUBSCRIPTION_URL,
  RETAIL_STORE_URL,
  // REVIEW_URL,
  SEO_OPTIMIZATION_URL,
  SETTING_URL,
  SUBSCRIPTION_MAIL_URL,
  USER_GIFT_CARD_URL,
  USER_MANAGEMENT_URL,
  VARIANT_MANAGEMENT_URL,
} from "../constant/url";
import { TbGiftCard } from "react-icons/tb";

export const sidebarcontent = [
  {
    title: "Dashboard",
    route: DASHBOARD_URL,
    icon: AiOutlineDashboard,
  },
  {
    title: "Product Management",
    route: PRODUCT_MANAGEMENT_MAIN_URL,
    icon: AiOutlineProduct,
    subMenu: [
      {
        title: "Product",
        route: PRODUCT_MANAGEMENT_URL,
        icon: BsBoxes,
      },
      {
        title: "Category",
        route: CATAGORY_MANAGEMENT_URL,
        icon: MdOutlineCategory,
      },

      {
        title: "Variant",
        route: VARIANT_MANAGEMENT_URL,
        icon: IoFilterCircleOutline,
      },
      {
        title: "Kits",
        route: KIT_MANAGEMENT_URL,
        icon: SlSocialDropbox,
      },
      {
        title: "Product Subscription",
        route: PRODUCT_SUBSCRIPTION_URL,
        icon: GoBell,
      },
      // Add more submenu items as needed
    ],
  },
  {
    title: "Order Management",
    route: ORDER_MANAGEMENT_URL,
    icon: MdOutlineShoppingCart,
  },
  {
    title: "Payment Management",
    route: PAYMENT_MANAGEMENT_URL,
    icon: MdOutlinePayment,
  },
  {
    title: "User Management",
    route: USER_MANAGEMENT_URL,
    icon: FaRegUser,
  },
  {
    title: "Coupon Management",
    route: COUPON_MANAGEMENT_URL,
    icon: RiCoupon2Line,
  },
  {
    title: "Gift Card",
    route: GIFT_CARD_URL,
    icon: CiGift,
    subMenu: [
      {
        title: "Gift cards",
        route: GIFT_CARDS_URL,
        icon: MdCardGiftcard,
      },
      {
        title: "User Gift Cards",
        route: USER_GIFT_CARD_URL,
        icon: TbGiftCard,
      },
    ]
  },
  {
    title: "Content Management",
    route: COUPON_MANAGEMENT_URL,
    icon: BiBookContent,
    subMenu: [
      {
        title: "Retail Stores",
        route: RETAIL_STORE_URL,
        icon: IoStorefrontOutline,
      },

      {
        title: "SEO Optimazation",
        route: SEO_OPTIMIZATION_URL,
        icon: MdOutlineScreenSearchDesktop,
      },
      {
        title: "About us",
        route: ABOUT_US_URL,
        icon: PiUsersThree,
      },
      {
        title: "Pages",
        route: INFO_PAGES_URL,
        icon: IoDocumentTextOutline,
      },
      {
        title: "Community Event",
        route: COMMUNITY_EVENT_URL,
        icon: LuCalendarDays,
      },
      {
        title: "Feedback",
        route: FEEDBACK_URL,
        icon: MdOutlineFeedback,
      },
      // {
      //   title: "Review",
      //   route: REVIEW_URL,
      //   icon: TiMessages,
      // },
      {
        title: "Catering",
        route: CATERING_URL,
        icon: MdOutlineFoodBank,
      },

      {
        title: "Subscription Email",
        route: SUBSCRIPTION_MAIL_URL,
        icon: MdOutlineMailOutline,
      },
    ],
  },
  {
    title: "Setting",
    route: SETTING_URL,
    icon: MdOutlineSettings,
    subMenu: [
      {
        title: "Footer",
        route: FOOTER_URL,
        icon: IoIosInformationCircleOutline,
      },
      {
        title: "Orders",
        route: FOOTER_URL_ORDERS,
        icon: MdOutlineShoppingCart,
      },
    ],
  },
];

export const deleteContaint = {
  title: "Delete Category",
  description: "Are you sure you want to delete this Category ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteFeedbackContaint = {
  title: "Delete Feedback",
  description: "Are you sure you want to delete this Feedback ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteCateringContaint = {
  title: "Delete Catering",
  description: "Are you sure you want to delete this Catering ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteFundraiseContaint = {
  title: "Delete Fund Raising",
  description: "Are you sure you want to delete this Fund Raising ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteRetailContaint = {
  title: "Delete Retail Store",
  description: "Are you sure you want to delete this Retail store ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteReviewContaint = {
  title: "Delete Review",
  description: "Are you sure you want to delete this Review ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteVariantContaint = {
  title: "Delete Variant",
  description: "Are you sure you want to delete this Variant ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteAboutusContaint = {
  title: "Delete Story",
  description: "Are you sure you want to delete this Story ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteProductContaint = {
  title: "Delete Product",
  description: "Are you sure you want to delete this product ?",
  submit: "Confirm",
  close: "Close",
};

export const deletePageContaint = {
  title: "Delete Page",
  description: "Are you sure you want to delete this page ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteKitContaint = {
  title: "Delete Kit",
  description: "Are you sure you want to delete this Kit ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteproductSubscriptionContaint = {
  title: "Delete Product Subscription",
  description: "Are you sure you want to delete this Product Subscription ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteCommunityEventContaint = {
  title: "Delete Community Event",
  description: "Are you sure you want to delete this Community Event ?",
  submit: "Confirm",
  close: "Close",
};

export const logoutContaint = {
  title: "Log Out",
  description: "Are you sure you want to Log out ?",
  submit: "Confirm",
  close: "Close",
};

export const deleteCouponContaint = {
  title: "Delete Coupon",
  description: "Are you sure you want to delete this Coupon ?",
  submit: "Confirm",
  close: "Close",
};

export const editStatusContaint = {
  title: "Change Status",
  description: "Are you sure you want to change status ?",
  submit: "Confirm",
  close: "Close",
};

export const InfoPageForm = {
  title: "Pages",
  submit: "Submit",
};

export const addformContaint = {
  title: "Add Product",
  submit: "Add Product",
  close: "Cancel",
};

export const addStoryformContaint = {
  title: "Add Story",
  submit: "Add Story",
  close: "Cancel",
};
export const editStoryformContaint = {
  title: "Edit Story",
  submit: "Edit Story",
  close: "Cancel",
};

export const editformContaint = {
  title: "Edit Product",
  submit: "Edit Product",
  close: "Cancel",
};

export const pageDropdownType = [
  // { name: "Terms & Condition" },
  { name: "Terms of Shipping" },
  { name: "Terms of Service" },
  { name: "Privacy Policy" },
];

export const initialProductDetail = {
  recipient_name:"",
  exclude_from_pack_kit:"",
  sequence_no:"",
  product_type: "",
  type: "",
  category: "",
  variant: "",
  kit: "",
  name: "",
  image: "",
  description: "",
  short_description: "",
  nutrition_facts: "",
  weight: "",
  dimension: { length: "0", width: "0", height: "0" },
  sale_price: "",
  regular_price: "",
  ingredients: "",
  tax: "",
  in_stock: false,
  has_subscription: false,
  has_shipping: false,
  purchase_note: "",
  slug: "",
  is_active: false,
  id: "",
  created_at: "",
  updated_at: "",
};

export const initialUserDetail = {
  addresses: {
    billingAddres:[],
    shippingAddress:[]
  },
  created_at: "",
  display_name: "",
  email: "",
  has_mail_subscription: false,
  id: "",
  is_active: false,
  phone: "",
  updated_at: "",
  user_type: "",
  username: "",
};

export const initialFooterSetting = {
  data: {
    address: "",
    contact: {
      email: "",
      phone: "",
    },
    social: {
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "",
    },
    shipping_charge: "",
    local_shipping_charge:"",
    non_local_shipping_charge:"",
    additional_quantity_charge:"",
  },
  id: "",
  created_at: "",
  updated_at: "",
};

export const initialShippingdate ={
  data:{
    excluded_year:"",
    excluded_months:"",
    excluded_days:{
      from:"",
      to:""
    },
    orders_per_day:""
  },
  id: "",
  created_at: "",
  updated_at: "",
}

export const initialPage = {
  pagination: { current_page: "0", record_limit: "0", total_records: "0" },
  data: [],
};

export const initialOrderDetail = {
  user_id: "",
  order_id: "",
  payment_id: "",
  username: "",
  email: "",
  order_type: "",
  total_amount: "",
  cart: [],
  promo_coupon: "",
  tip:0,
  is_paid: false,
  transaction_id: "",
  id: "",
  created_at: "",
  updated_at: "",
  order_note: "",
  gift_note: { from: "", to: "", message: "" },
  delivery_date: "",
  details: {
    customer_info: {
      name: "",
      phone: "",
      email: "",
      company_name: "",
    },
    billing_details: {
      street_address: "",
      apartment: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    },
    shipping_details: {
      name: "",
      company_name: "",
      street_address: "",
      apartment: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    },
  },
};


export const initialDashboard = {
  users_count: {
    total: null,
    total_active: null,
    total_inactive: null,
    count_today: null,
    count_past_week: null,
    count_past_month: null,
  },
  orders_count: {
    total: null,
    count_today: null,
    count_past_week: null,
    count_past_month: null,
  },
  payments_amount: {
    total: null,
    count_today: null,
    count_past_week: null,
    count_past_month: null,
  },
  coupons_count: {
    total: null,
    count_today: null,
    count_past_week: null,
    count_past_month: null,
  },
  past_week_payment_revenue: {
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
    Monday: 0,
    Tuesday: 0,
  },
  past_year_payment_revenue: {
    June: 0,
    May: 0,
    April: 0,
    March: 0,
    February: 0,
    January: 0,
    December: 0,
    November: 0,
    October: 0,
    September: 0,
    August: 0,
    July: 0,
  },
  past_week_order_revenue: {
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
    Monday: 0,
    Tuesday: 0,
  },
  past_year_order_revenue: {
    June: 0,
    May: 0,
    April: 0,
    March: 0,
    February: 0,
    January: 0,
    December: 0,
    November: 0,
    October: 0,
    September: 0,
    August: 0,
    July: 0,
  },
};

export const breadcrumbNameMap: { [key: string]: string } = {
  dashboard: "Dashboard",
  "product-management": "Product Management",
  product: "Product",
  "coupon-management": "Coupon Management",
  category: "Category",
  variant: "Variant",
  kit: "Kit",
  "product-subscription": "Product Subscription",
  "order-management": "Order Management",
  "payment-management": "Payment Management",
  "user-management": "User Management",
  "retail-store": "Retail Store",
  "content-management": "Content Management",
  "seo-optimization": "SEO Optimization",
  pages: "Pages",
  "add-page": "Add Pages",
  "community-event": "Community Event",
  feedback: "Feedback",
  review: "Review",
  catering: "Catering",
  "subscription-email": "Subscription Email",
  "footer-info": "Footer Information",
  setting: "Setting",
  "edit-product": "Edit Product",
  "add-product": "Add Product",
  "about-us": "About Us",
  "update-page": "Edit Page",
  "add-aboutus": "Add About Us",
  "edit-aboutus": "Edit About Us",
  "gift-card": "Gift Card",
  "gift-cards": "Gift Cards",
  "user-gift-card":'User Gift Card',
  'add-seo': "Add SEO"
  // Add more mappings as needed
};

// List of paths that do not have components
export const noComponentPaths = [
  "product-management",
  "update-page",
  "content-management",
  "edit-product",
  "add-product",
  "setting",
  'gift-card',
  'edit-aboutus'
];
